<div class="card-body">
    <div class="mb-5">
		<h4 class="mb-1">Address Details</h4>
	</div>
    <form [formGroup]="addressForm">
        <div class="vstack gap-5">
            <div class="row mt-1">
                <div class="col-md-6">
                    <div>
                        <label class="text-sm text-muted">Address 1</label> 
                        <input type="text"  class="form-control" id="address_1" formControlName="address_1">
                    </div>
                </div>
                <div class="col-md-6">
                    <div>
                        <label class="text-sm text-muted">Address 2</label> 
                        <input type="text"  class="form-control" id="address_2" formControlName="address_2">
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-md-6">
                    <div>
                        <label class="text-sm text-muted">Address 3</label> 
                        <input type="text"  class="form-control" id="address_3" formControlName="address_3">
                    </div>
                </div>
                <div class="col-md-6">
                    <div>
                        <label class="text-sm text-muted">Post Code</label> 
                        <input type="text"  class="form-control" id="post_zip_code" formControlName="post_zip_code">
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-md-6">
                    <div>
                        <label class="text-sm text-muted">City</label> 
                        <input type="text"  class="form-control" id="city" formControlName="city">
                    </div>
                </div>
                <div class="col-md-6">
                    <div>
                        <label class="text-sm text-muted">Country</label> 
                        <input type="text"  class="form-control" id="country" formControlName="country">
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="error" *ngIf="error"><span>{{error}}</span></div>
    <div class="hstack gap-2 justify-content-end mt-5"><button class="btn btn-sm btn-primary"  (click)="saveAgencyAddress()">Save</button></div>
</div>