<div class="mainContainer card overflow-auto margin mt-4">
    <div class="loader" *ngIf="loading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
    <div class="reminder mb-0 d-flex bg-light justify-content-between flex-column flex-sm-row">
        <h5 class="card-title ps-4 mb-0 tableTitle">Invoices</h5>
    </div>
    <table matSort class="table table-hover table-nowrap">
    <thead class="table-light">
        <tr>
            <th mat-sort-header="candidate" scope="col">Contractor</th>
            <th mat-sort-header="client" scope="col">Client</th>
            <th mat-sort-header="job" scope="col">Job</th>
            <th mat-sort-header="invoice_date" scope="col">Invoice Date</th>
            <th mat-sort-header="due_date" scope="col">Due Date</th>
            <th mat-sort-header="total_pay" scope="col">Total Pay</th>
            <th mat-sort-header="status" scope="col">Status</th>
            <th mat-sort-header="actions" scope="col">Actions</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="invoices.length > 0">
            <tr class="dataColumn" *ngFor="let invoice of invoices">
                <td>{{invoice.candidate}}</td>
                <td>{{invoice.client}}</td>
                <td>{{invoice.job}}</td>
                <td>{{invoice.invoice_date}}</td>
                <td>{{invoice.due_date}}</td>
                <td>{{invoice.candidate_currency_format}} {{invoice.total_pay}}</td>
                <td>
                    <div class="sheetStatus bg-opacity-20" [ngClass]="
                    invoice.contractor_sent === INVOICE_STATUS.INVOICE_STATUS_CREATED ? 'bg-primary text-primary':
                    invoice.contractor_sent === INVOICE_STATUS.INVOICE_STATUS_SENT ? 'bg-success text-success':''">
                      <i class="bi" [ngClass]="
                      invoice.contractor_sent === INVOICE_STATUS.INVOICE_STATUS_CREATED ? 'bi-stopwatch-fill':
                      invoice.contractor_sent === INVOICE_STATUS.INVOICE_STATUS_SENT ? 'bi-check-circle-fill':''"></i>
                      <span class="d-flex align-items-center">{{invoice.contractor_sent}}</span>
                    </div>
                </td>
                <td>
                    <div (click)="viewInvoiceDetails(invoice, 'contractor')" style="color: #6b7b93;" class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7" matTooltip="Edit Contractor Invoice" [matTooltipPosition]="'left'">
                        <i class="bi bi-eye"></i>
                    </div>
                    
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf="invoices.length == 0">
            <tr>
              <td class="text-heading font-bold text-center">No records found</td>
            </tr>
          </ng-container>
    </tbody>
</table>
<app-api-paginator *ngIf="totalCount" [totalCount]="totalCount" (onStepChange)="onStepChange($event)">
</app-api-paginator>
</div>

