import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { roleConstant } from 'src/app/app.constant';
import { AlertModal } from 'src/app/app.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { DataSharedService } from 'src/app/services/data-shared.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  userForm: FormGroup | any;
  name!: string;
  email!: string;
  error!: string;
  user!: any;
  isVatApplicable: boolean;
  paymentTerms: any = {}

  collapsedSections: { [key: string]: boolean } = {
    vatDetails: true,
    addressDetails: true,
    additionalDetails: true,
    limitedCompanyDetails: true,
  };

  constructor(
    private timesheetService: TimesheetService,
    private activeModalService: NgbActiveModal,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    public dataSharedService: DataSharedService
  ) {
    this.user = this.authService.userValue;
  }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      identifier: new FormControl('', []),
      address_1: new FormControl('', Validators.required),
			address_2: new FormControl('', []),
			post_zip_code: new FormControl('', []),
			country: new FormControl('', []),
      ssn: new FormControl('', []),
      po_number: new FormControl('', []),
      bank_name: new FormControl('', []),
      account_number: new FormControl('', []),
      payment_terms: new FormControl('', []),
      limited_company_name: new FormControl('', []),
      limited_company_email: new FormControl('', []),
      limited_company_address: new FormControl('', []),
      limited_company_bank_name: new FormControl('', []),
      limited_company_bank_address: new FormControl('', []),
      limited_company_account_number: new FormControl('', []),
      limited_company_sort_code: new FormControl('', []),
      limited_company_swift_code: new FormControl('', []),
      registration_number: new FormControl('', []),
      sales_tax_number: new FormControl('', []),
      vat_code: new FormControl('', []),
      is_vat_applicable: new FormControl('', []),
      currency_format: new FormControl(localStorage.getItem('currencyFormat') ? localStorage.getItem('currencyFormat') : '', []),
    });
  }

  get hasValue() {
    let hasValue = false;
    Object.keys(this.userForm.value).forEach(element => {
      if (this.userForm.value[element]) {
        hasValue = true;
      }
    });
    return hasValue
  }

  toggleSection(section: string) {
    this.collapsedSections[section] = !this.collapsedSections[section];
  }

  onClose() {
    if (!this.hasValue) {
      this.activeModalService.close();
      return
    }
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      modalDialogClass: 'alert-modal modal-dialog-centered',
      backdrop: false,
    });
    let data: AlertModal = {
      message: 'Are you sure you want to discard Candidate Creation?',
      yesAction: 'Discard',
      noAction: 'Cancel',
      yesActionColor: '#0566EA',
      noActionColor: 'transparent',
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  onSubmit() {
    this.error = '';
    const payload = {
      first_name: this.userForm.value.first_name,
      last_name: this.userForm.value.last_name,
      email: this.userForm.value.email,
      ssn: this.userForm.value.ssn,
      identifier: this.userForm.value.identifier,
      po_number: this.userForm.value.po_number,
      payment_terms: this.userForm.value.payment_terms,
      limited_company_name: this.userForm.value.limited_company_name,
      limited_company_email: this.userForm.value.limited_company_email,
      limited_company_address: this.userForm.value.limited_company_address,
      limited_company_bank_name: this.userForm.value.limited_company_bank_name,
      limited_company_bank_address: this.userForm.value.limited_company_bank_address,
      limited_company_account_number: this.userForm.value.limited_company_account_number,
      limited_company_sort_code: this.userForm.value.limited_company_sort_code,
      limited_company_swift_code: this.userForm.value.limited_company_swift_code,
      currency_format: this.userForm.value.currency_format,
      registration_number: this.userForm.value.registration_number,
      sales_tax_number: this.userForm.value.sales_tax_number,
      vat_code: this.userForm.value.vat_code,
      is_vat_applicable: this.userForm.value.is_vat_applicable,
      account_number: this.userForm.value.account_number,
      bank_name: this.userForm.value.bank_name,
      role_uuid: roleConstant.CANDIDATE,
      agency_uuid: this.user.agency_uuid,
      address: {
        address_1: this.userForm.value.address_1,
        address_2: this.userForm.value.address_2,
        post_zip_code: this.userForm.value.post_zip_code,
        country: this.userForm.value.country,
      }
    }
    this.timesheetService.createCandidate(payload).subscribe(res => {
      this.activeModalService.close({ refresh: true });
    }, error => {
      console.error(error);
      this.error = error;
    });
  }

}
