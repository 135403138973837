<div class="card-body">
	<form [formGroup]="companyForm">
		<div class="vstack gap-5">
			<div class="row mt-1">
				<div class="col-md-6">
					<div>
						<label class="text-sm text-muted">Email</label> 
						<input type="text"  class="form-control" formControlName="email">
					</div>
				</div>
				<div class="col-md-6">
					<div>
						<label class="text-sm text-muted">Phone Number</label> 
						<input type="text"  class="form-control" formControlName="phone_number">
					</div>
				</div>
			</div>
			<div class="row mt-1">
				<div class="col-md-6">
					<div>
						<label class="text-sm text-muted">VAT Number</label> 
						<input type="text"  class="form-control" formControlName="vat_number">
					</div>
				</div>
				<div class="col-md-6">
					<div>
						<label class="text-sm text-muted">Company Number</label> 
						<input type="text"  class="form-control" formControlName="company_number">
					</div>
				</div>
			</div>
		</div>
	</form>
    
    <div class="hstack gap-2 justify-content-end mt-5"><button class="btn btn-sm btn-primary" (click)="saveAgencyDetails()">Save</button></div>
</div>