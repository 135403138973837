import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataSharedService } from '../../services/data-shared.service';
import { SuccessModal } from 'src/app/app.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessModalComponent } from '../../components/success-modal/success-modal.component';


@Component({
  selector: 'app-address-component',
  templateUrl: './address-component.component.html',
  styleUrls: ['./address-component.component.css']
})
export class AddressComponentComponent implements OnInit {

  addressForm: FormGroup | any;
  error!: string;
  formValid: boolean = false;
  agencyAddress: any;

  constructor(public dataSharedService: DataSharedService,
     private modalService: NgbModal,
     private activeModalService: NgbActiveModal,
  ) {
    
   }

  async ngOnInit() {
    this.fetchAddress();
    this.addressForm = new FormGroup({
			address_1: new FormControl(this.agencyAddress ? this.agencyAddress.address_1 : '', [Validators.required]),
			address_2: new FormControl(this.agencyAddress ?this.agencyAddress.address_2 : '', []),
			address_3: new FormControl(this.agencyAddress ?this.agencyAddress.address_3 : '', []),
			post_zip_code: new FormControl(this.agencyAddress ?this.agencyAddress.post_zip_code: '', []),
			country: new FormControl(this.agencyAddress ?this.agencyAddress.country: '', []),
			city: new FormControl(this.agencyAddress ?this.agencyAddress.city: '', []),
		});
   
  }

   async fetchAddress() {
    this.dataSharedService.getAgencyAddress().subscribe((res: any) => {
      this.agencyAddress = res.address[0];
      this.addressForm.patchValue({
        address_1: res.address[0].address_1,
        address_2: res.address[0].address_2,
        address_3: res.address[0].address_3,
        post_zip_code: res.address[0].post_zip_code,
        country: res.address[0].country,
        city: res.address[0].city,
      })
    });
  }


  async addressValidation(event: any) {
		this.error = '';
		let hasErr = false;
		let address1Element = document.getElementById('address_1');
		if (this.addressForm.controls.name.errors || this.addressForm.controls.name.value.trim() == '') {
			if (address1Element !== null) {
				hasErr = true;
			}
		}

		if (hasErr) {
			this.formValid = false;
		} else {
			this.formValid = true;
		}
	}

  saveAgencyAddress() {
		this.dataSharedService.setAgencyAddress(this.addressForm.value).subscribe(res => {
      this.successModal('Updated Successfully!');
      this.ngOnInit();
		}, error => {
			// this.loading = false;
    });
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }
}
