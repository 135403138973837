import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { statusConstant } from 'src/app/app.constant';
import { AlertModal, auditTrailHeader } from 'src/app/app.model';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.css'],
})
export class AuditTrailComponent implements OnInit {
  @Input() header!: auditTrailHeader;
  @Input() type!: string;
  @Input() uuid!: string;
  @Input() from!: string;
  @Input() auditTrailActivities?: any;
  isModalCloseIcon = false;
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private timesheetService: TimesheetService,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<AuditTrailComponent>,
    public dataSharedService: DataSharedService,
  ) {
    if (!this.header) {
      this.type = data?.type;
      this.uuid = data?.uuid;
      this.header = data?.header;
    }
    this.isModalCloseIcon = data.isIcon;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.getAuditTrailData();
    this.loading = false;
  }

  getAuditTrailData() {
    return new Promise(async (resolve) => {
      if (!this.auditTrailActivities) {
        this.auditTrailActivities = await new Promise(resolve => this.timesheetService.getAuditTrail(this.type, this.uuid).subscribe((res: any) => resolve(res.data)))
      }
      this.auditTrailActivities.forEach((activity: any) => {
        activity.day = this.datePipe.transform(activity.event_date, 'EEEE');
        // activity.time = this.datePipe.transform(
        //   activity.event_date,
        //   'hh:mm a'
        // );
        activity.time = moment.utc(activity.event_date).tz(moment.tz.guess()).format("hh:mm A ");
        if (this.type === 'timesheet') {
          if (
            activity?.new_values?.status === statusConstant.SUBMITTED &&
            activity?.old_values?.status &&
            activity?.old_values?.status !== statusConstant.PENDING
          ) {
            activity.text = 'edited the timesheet';
          } else {
            activity.text = `${activity?.new_values?.status} a timesheet`;
          }
        } else if (this.type === 'job') {
          if(activity?.new_values?.is_active == 3) {
            activity.text = `submitted the onboarding form details`;
          } else if(activity?.new_values?.is_active == 1) {
            activity.text = `approved the onboarding form details & job moved to Active`;
          } else if(activity?.new_values?.is_active == 4) {
            activity.text = `rejected the onboarding form details`;
          } else if(activity?.new_values?.is_active == 5) {
            activity.text = `permanent rejected the onboarding form details`;
          } else if(activity?.new_values?.is_active == 6) {
            activity.text = `resubmitted the onboarding form details`;
          } else {
            activity.text = `added the job ${activity?.new_values?.name}`;
          }
        }
      });
      resolve(true);
    });
  }

  close() {
    this.dialogRef.close();
  }
}
