import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataSharedService } from '../../services/data-shared.service';
import { SuccessModal } from 'src/app/app.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessModalComponent } from '../../components/success-modal/success-modal.component';

@Component({
  selector: 'app-bank-details-component',
  templateUrl: './bank-details-component.component.html',
  styleUrls: ['./bank-details-component.component.css']
})
export class BankDetailsComponentComponent implements OnInit {

  bankForm: FormGroup | any;
  error!: string;
  formValid: boolean = false;
  bankDetails: any;


  constructor(public dataSharedService: DataSharedService,
    private modalService: NgbModal,
    private activeModalService: NgbActiveModal,) { }

  ngOnInit(): void {
    this.fetchBankDetails();
    this.bankForm = new FormGroup({
			bank_name: new FormControl(this.bankDetails ? this.bankDetails.bank_name : '', [Validators.required]),
			sort_code: new FormControl(this.bankDetails ?this.bankDetails.sort_code : '', []),
			account_number: new FormControl(this.bankDetails ?this.bankDetails.account_number : '', []),
			swift_bic_number: new FormControl(this.bankDetails ?this.bankDetails.swift_bic_number: '', []),
			address: new FormControl(this.bankDetails ? this.bankDetails.address: '', []),
		});
  }

  async fetchBankDetails() {
    this.dataSharedService.getAgencyBankDetails().subscribe((res: any) => {
      console.log(res.bank_details);
      this.bankDetails = res.bank_details[0];
      this.bankForm.patchValue({
        bank_name: res.bank_details[0] ? res.bank_details[0].bank_name : '',
        sort_code: res.bank_details[0] ? res.bank_details[0].sort_code : '',
        account_number: res.bank_details[0] ? res.bank_details[0].account_number : '',
        swift_bic_number: res.bank_details[0] ? res.bank_details[0].swift_bic_number : '',
        address: res.bank_details[0] ? res.bank_details[0].address : '',
      })
    });
  }

  saveAgencyBankDetails() {
		this.dataSharedService.setAgencyBankDetails(this.bankForm.value).subscribe(res => {
      this.successModal('Updated Successfully!');
      this.ngOnInit();
		}, error => {
			// this.loading = false;
    });
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

}
