import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { DataSharedService } from '../services/data-shared.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendEmailComponent } from '../components/send-email/send-email.component';
import  { messageConstant } from '../app.constant';
import { AwaitingApprovalsModalComponent } from '../components/awaiting-approvals-modal/awaiting-approvals-modal.component';
import { Router } from '@angular/router';
import { TimesheetDetailsComponent } from '../timesheet-details/timesheet-details.component';

@Component({
  selector: 'app-awaiting-approval-timesheets',
  templateUrl: './awaiting-approval-timesheets.component.html',
  styleUrls: ['./awaiting-approval-timesheets.component.css']
})
export class AwaitingApprovalTimesheetsComponent implements OnInit {
  @Input() awaitingApprovalTimesheets?: any;
  @Input() awaitingApprovalTimesheetsCount?: any;
  public permissions: any = {};
  public user: any;
  public isCollapsed = false;
  candidateTrackingHrs: any = localStorage.getItem('candidateTrackingHrs') ? localStorage.getItem('candidateTrackingHrs') : 1; 


  constructor(
    public authService: AuthenticationService,
    public dataSharedService: DataSharedService,
    public dialog: MatDialog,
    private modalService: NgbModal,
    private router: Router,
  ) { 
    this.user = this.authService.userValue;
    this.permissions.candidates = this.user.permissions.find((x: any) => x.permission === 'Candidates');
  }

  ngOnInit(): void {
  }

  openAllAwaitingApprovals() {
    this.dialog.open(AwaitingApprovalsModalComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '98%',
      disableClose: true,
      panelClass: 'timesheetDetailDialog',
    });
  }

  openSendReminderPopUp(clientUuid:any, candidatName:string, jobName:string, timesheetUuid: string, agencyUuid: string) {
    const modalRef = this.modalService.open(SendEmailComponent, {
      size: 'md',
      modalDialogClass: 'add-reminder-modal'
    });

    let message = messageConstant.AWAITING_APPROVAL_MESSAGE.
    replace('{{contractor_name}}', candidatName).
    replace('{{job_name}}', jobName);
   
    modalRef.componentInstance.clientUuid = clientUuid;
    modalRef.componentInstance.timesheetUuid = timesheetUuid;
    modalRef.componentInstance.agencyUuid = agencyUuid;
    modalRef.componentInstance.userSubject = messageConstant.AWAITING_APPROVAL_SUBJECT;
    modalRef.componentInstance.userMessage = message;
   
    modalRef.result.then((data) => {
      if (data?.data === true) {
       
      }
    });
  }

  redirect(url: string, name: any) {
    if ((url === '/jobs' && this.permissions.jobs) || (url === '/candidates' && this.permissions.candidates) || (url === '/clients' && this.permissions.clients)) {
      this.router.navigate([url], { queryParams: { name } });
    }
  }

  openTimesheetDetails(timesheet: any, status: string, readonly: boolean) {
    let modalRef = this.dialog.open(TimesheetDetailsComponent, {
      width: '90%',
      maxWidth: '800px',
      maxHeight: '700px',
      height: '90%',
      disableClose: true,
      panelClass: 'timesheetDetailDialog',
      data: {
        timesheetId: timesheet.uuid,
        timesheet,
        readonly,
        status: status,
      },
    });

    modalRef.afterClosed().subscribe(result => {
      if (result?.refresh) {
        this.ngOnInit();
      }
    });
  }

}
