import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { roleConstant } from '../app.constant';


@Component({
  selector: 'app-address-bank-details',
  templateUrl: './address-bank-details.component.html',
  styleUrls: ['./address-bank-details.component.css']
})
export class AddressBankDetailsComponent implements OnInit {
  user: any;
  ROLES = roleConstant;

  constructor(
    private authService: AuthenticationService,
  ) {
    this.user = this.authService.userValue;
   }

  ngOnInit(): void {
   
  }

}
