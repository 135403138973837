<!-- <div class="accordion" id="accordionAwaitingApprovals">
  <div class="card h-full accordion">
      <div class="card-header" data-toggle="collapse" data-target="#collapseAwaitingApprovals" aria-expanded="true" (click)="isCollapsed = !isCollapsed"  
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">     
          <span class="font-semibold text-muted">Awaiting Timesheets Approvals ({{awaitingApprovalTimesheetsCount}})</span>
          <span class="accicon"><i [ngClass]="isCollapsed ? 'bi bi-chevron-down':'bi bi-chevron-up'"></i></span>
      </div>
      <div id="collapseAwaitingApprovals" class="collapse show" [ngbCollapse]="isCollapsed" data-parent="#accordionAwaitingApprovals">
          <div class="card-body">
              <div class="ms-auto text-end">
                <a [routerLink]="" (click)="openAllAwaitingApprovals()" class="text-sm font-semibold see-all">See all</a>
            </div>
              <div class="mt-2 mb-0 text-sm">
                <div class="list-group gap-4">
                  <div  *ngFor="let timesheet of awaitingApprovalTimesheets; let i = index" class="list-group-item d-flex align-items-center border rounded">
                    <div class="me-4">
                      <div class="avatar rounded-circle" *ngIf="timesheet.profile_pic">
                        <img alt="{{timesheet.candidate}}" src="{{dataSharedService.getImage(timesheet.profile_pic)}}">
                      </div>
                      <div *ngIf="!timesheet.profile_pic" class="cotractorInitialIcon bg-primary bg-opacity-20 h-7 w-7">
                        <span class="text-primary fs-10x">{{authService.getInitials(timesheet.candidate)}}</span>
                      </div>
                    </div>
                    <div class="flex-fill">
                      <a [routerLink]="" (click)="redirect('/candidates', timesheet.candidate)" class="d-block h6 font-semibold mb-1">{{timesheet.candidate}}</a>
                      <span class="d-block text-sm text-muted">{{timesheet.job}}, {{timesheet.client}}</span>
                    </div>
                    <div class="ms-auto text-end">
                      <button type="button"(click)="openSendReminderPopUp(timesheet.client_uuid, timesheet.candidate, timesheet.job)" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                        <i class="bi bi-envelope"></i>
                      </button>
                    </div>
                  </div>
                </div>
                  </div>
          </div>
      </div>
  </div>
</div> -->


<div class="card h-full">
  <div class="card-body">
    <div class="row">
      <div class="col">
        <span class="h6 font-semibold text-muted text-sm d-block mb-2">Awaiting Timesheets Approvals
          ({{awaitingApprovalTimesheetsCount}})</span>
      </div>
      <div class="col-auto">
        <a [routerLink]="" (click)="openAllAwaitingApprovals()" class="text-sm font-semibold see-all">See all</a>
      </div>
    </div>
    <div class="mt-2 mb-0 text-sm">
      <div class="list-group gap-4">
        <div *ngFor="let timesheet of awaitingApprovalTimesheets; let i = index"
          class="list-group-item d-flex align-items-center border rounded">

          <div class="me-4 profile_pic">
            <div class="" (click)="redirect('/candidates', timesheet.candidate)" *ngIf="timesheet.profile_pic">
              <img alt="{{timesheet.candidate}}" src="{{dataSharedService.getImage(timesheet.profile_pic)}}"
                class="avatar avatar-sm rounded-circle me-2" />
            </div>
            <div *ngIf="!timesheet.profile_pic" (click)="redirect('/candidates', timesheet.candidate)"
              class="cotractorInitialIcon bg-primary bg-opacity-20 h-7 w-7">
              <span class="text-primary fs-10x">{{authService.getInitials(timesheet.candidate)}}</span>
            </div>
          </div>

          <div class="flex-fill">
            <a *ngIf="candidateTrackingHrs != 2" [routerLink]="" (click)="redirect('/candidates', timesheet.candidate)"
              class="d-block h6 font-semibold mb-1 candidate_name">{{timesheet.candidate}}, {{timesheet.total_hours}}
            </a>
            <a  *ngIf="candidateTrackingHrs == 2" [routerLink]="" (click)="redirect('/candidates', timesheet.candidate)"
              class="d-block h6 font-semibold mb-1 candidate_name">{{timesheet.candidate}}, {{timesheet.total_days}} Days
            </a>
            <div class="dateIndicator"></div>
            <a [routerLink]="" (click)="openTimesheetDetails(timesheet, timesheet.status, true)"
              class="timesheet_dates">{{dataSharedService.tranformDate(timesheet.start_date)}} to
              {{dataSharedService.tranformDate(timesheet.end_date)}}</a>
            <span class="d-block text-sm text-muted">{{timesheet.job}}, {{timesheet.client}}</span>
          </div>
          <div class="ms-auto text-end">
            <button type="button"
              (click)="openSendReminderPopUp(timesheet.client_uuid, timesheet.candidate, timesheet.job, timesheet.uuid, timesheet.agency_uuid)"
              class="btn btn-sm btn-square btn-neutral text-danger-hover">
              <i class="bi bi-envelope"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>