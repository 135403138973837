import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataSharedService } from '../../services/data-shared.service';
import { SuccessModal } from 'src/app/app.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessModalComponent } from '../../components/success-modal/success-modal.component';


@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent implements OnInit {

  companyForm: FormGroup | any;
  error!: string;
  formValid: boolean = false;
  companyDetails: any;


  constructor(public dataSharedService: DataSharedService,
      private modalService: NgbModal,
      private activeModalService: NgbActiveModal,) { }

  ngOnInit(): void {
    this.fetchCompanyDetils();
    this.companyForm = new FormGroup({
			email: new FormControl(this.companyDetails ? this.companyDetails.email : '', [Validators.required]),
			phone_number: new FormControl(this.companyDetails ?this.companyDetails.phone_number : '', []),
			vat_number: new FormControl(this.companyDetails ?this.companyDetails.vat_number : '', []),
			company_number: new FormControl(this.companyDetails ?this.companyDetails.company_number: '', []),
		});
  }


  async fetchCompanyDetils() {
    this.dataSharedService.getCompanyDetails().subscribe((res: any) => {
      console.log(res.agency_details);
      this.companyDetails = res.agency_details[0];
      this.companyForm.patchValue({
        email: res.agency_details[0] ? res.agency_details[0].email : '',
        phone_number: res.agency_details[0] ? res.agency_details[0].phone_number : '',
        vat_number: res.agency_details[0] ? res.agency_details[0].vat_number : '',
        company_number: res.agency_details[0] ? res.agency_details[0].company_number : '',
      });
    });
  }

  saveAgencyDetails() {
		this.dataSharedService.setCompanyDetails(this.companyForm.value).subscribe(res => {
      this.successModal('Updated Successfully!');
      this.ngOnInit();
		}, error => {
			// this.loading = false;
    });
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

}
