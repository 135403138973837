<div class="loader" *ngIf="loading">
  <mat-spinner diameter="30"></mat-spinner>
</div>

<div class="container-fluid">
  <div class="row g-6 mb-6">
    <div class="col-xl-12">
      <div class="card" #mainContainer [ngClass]="isOpen ? 'ownShadow':''">

        <div class="card-header">
          <div class="row w-100 justify-content-between align-items-center">

            <div class="col-sm col-12 mb-2 gap-2 d-flex">
              <h3 class="h3 ls-tight px-2 py-2">Timesheets</h3>
              <div class="bg-primary text-primary d-flex bg-opacity-20 rounded gap-2 px-3 py-2 timesheet-total">
                <i class="bi bi-check-circle-fill"></i>
                <span class="d-none d-sm-flex">Total Timesheets:</span>
                <span>{{totalTimesheetCount}}</span>
              </div>
            </div>

            <div class="col-sm-auto col-12 mb-2 d-flex gap-2 align-items-center">
              <div class="filter-daterangepicker">
                <mat-form-field appearance="legacy" class="datePickerStyle">
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate [(ngModel)]="start_date" placeholder="Start Date">
                    <input matEndDate [(ngModel)]="end_date" placeholder="End Date" (dateChange)="dateChanged($event)">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker touchUi="true" #picker></mat-date-range-picker>
                </mat-form-field>
                <mat-select panelClass="matRole timesheetDateFilterPanel"
                  class="form-control timesheet-filter-daterange" role="button"
                  (selectionChange)="onFilterChange(picker)" [(ngModel)]="selectedFilterOption">
                  <mat-option>Recently Submmitted</mat-option>
                  <mat-option [value]="option.label" *ngFor="let option of filterOptions">{{option.label}}</mat-option>
                  <mat-option value="datePicker" (click)="onFilterChange(picker)">Date picker</mat-option>
                  <mat-option value="{{custom_datepicker_text}}" class="d-none">{{custom_datepicker_text}}</mat-option>
                </mat-select>
              </div>

              <div class="filter-status d-flex">
                <button type="button"
                  class="btn btn-sm btn-neutral d-flex align-items-center px-4 timesheet-filter-status"
                  (click)="openStatusFilter(statusFilter)" matTooltip="Filter">
                  <i class="bi bi-funnel me-2"></i>
                  <span class="fs-7">Filters</span>
                  <span class="vr opacity-20 mx-3"></span>
                  <span class="text-xs text-primary">{{selectedStatusFilter.length}}</span>
                </button>
                <mat-select style="width: 0;" #statusFilter panelClass="matRole" panelClass="statusFilterPanel"
                  class="statusFilter" role="button" (selectionChange)="onStatusFilterChange($event)"
                  [(ngModel)]="selectedStatusFilter" [multiple]="true">
                  <mat-option *ngFor="let status of timesheetService.statusArr" [value]="status">{{status}}</mat-option>
                </mat-select>
              </div>
            </div>

            <div class="col-sm-auto col-12 mb-2 d-flex gap-2 justify-content-sm-end align-items-center">

              <i class="bi bi-arrow-clockwise d-flex justify-content-between fs-6 refreshIcon" *ngIf="!loading"
                matTooltip="Refresh" (click)="onRefresh()"></i>

              <div *ngIf="selecting">
                <button [disabled]="loading || !isSelected() || !isAllSelectedIsSubmitted()" (click)="onApproveAll()"
                  class="responseTimesheet">Approve</button>
                <button [disabled]="loading || !isSelected() || !isAllSelectedIsSubmitted()" (click)="onRejectAll()"
                  class="responseTimesheet" id="reject">Reject</button>
                <button [disabled]="loading" class="selectTimesheet" (click)="selectToggle()">Cancel</button>
              </div>
              <mat-select class="timesheet-filter-exporttimeSheet" style="width: 0;" #timesheetOptionsSelect
                panelClass="matRole" role="button">
                <mat-option *ngIf="user.role_uuid === ROLES.AGENCY || user.role_uuid === ROLES.CLIENT">
                  <a class="dropdown-item btn" (click)="selectToggle()">
                    {{selecting ? 'Cancel':'Select'}}
                  </a>
                </mat-option>
                <mat-option [disabled]="this.selecting && !isSelected()">
                  <a class="dropdown-item btn" (click)="exportTimeSheet(isSelected())">
                    Export TimeSheet
                  </a>
                </mat-option>
              </mat-select>
              <a class="text-muted" role="button" (click)="timesheetOptionsSelect.open()">
                <i class="bi bi-three-dots-vertical"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table matSort (matSortChange)="sortData($event)" class="table table-hover table-nowrap">
            <thead class="table-light" *ngIf="timesheet_fields && timesheet_fields?.length > 0">
              <tr>
                <th scope="col" *ngIf="selecting">
                  <input (change)="onSelectAll()" [(ngModel)]="selectAll" type="checkbox">
                </th>
                <ng-container *ngFor="let field of timesheet_fields" [ngSwitch]="field?.label">
                  <th mat-sort-header="job" scope="col" *ngSwitchCase="'Job'">Job</th>
                  <th mat-sort-header="candidate" scope="col" *ngSwitchCase="'Contractor'">Contractor</th>
                  <th mat-sort-header="client" scope="col" *ngSwitchCase="'Client'">Client</th>
                  <th mat-sort-header="start_date" scope="col" *ngSwitchCase="'Start Date'">Start Date</th>
                  <th mat-sort-header="end_date" scope="col" *ngSwitchCase="'End Date'">End Date</th>
                  <th mat-sort-header="status" scope="col" *ngSwitchCase="'Status'">Status</th>
                  <th scope="col" *ngSwitchCase="'Work Duration'">Work Duration</th>
                  <th scope="col" *ngSwitchCase="'Time Unit'">Time Unit</th>
                  <ng-container *ngSwitchCase="'Hours'">
                    <th scope="col" th_switchcase="Hours">Total Hours</th>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Days'">
                    <th scope="col" th_switchcase="Days">Total Days</th>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Start Time'">
                    <th scope="col" th_switchcase="Start Time">Total Hours</th>
                  </ng-container>
                </ng-container>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="sortedTimesheet.length == 0">
                <tr>
                  <td class="text-heading font-bold text-center">No records found</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="sortedTimesheet.length > 0">
                <tr class="dataColumn" *ngFor="let timesheet of sortedTimesheet">
                  <td class="text-heading font-bold" *ngIf="selecting"><input (change)="onSingleSelect()"
                      [(ngModel)]="timesheet.selected" type="checkbox">
                  </td>
                  <ng-container *ngFor="let field of timesheet_fields" [ngSwitch]="field?.label">
                    <td class="text-heading font-bold" *ngSwitchCase="'Job'"
                      (click)="this.permissions.assignments?redirectAssignments('/assignments', timesheet.job) : openTimesheetDetails(timesheet, timesheet.status, true)">
                      <div class="textWithIcon">
                        <i class="bi bi-briefcase"></i>
                        <span>{{timesheet.job}}</span>
                      </div>
                    </td>
                    <td *ngSwitchCase="'Contractor'"
                      (click)="permissions?.candidates?redirect('/candidates', timesheet.candidate) : openTimesheetDetails(timesheet, timesheet.status, true)">

                      <img class="avatar avatar-sm rounded-circle me-2"
                        src="{{dataSharedService.getImage(timesheet.profile_pic)}}" alt="..."
                        *ngIf="timesheet.profile_pic" />

                      <div *ngIf="!timesheet.profile_pic"
                        class="avatar avatar-sm rounded-circle me-2 cotractorInitialIcon bg-primary bg-opacity-20 h-7 w-7">
                        <span class="text-primary fs-10x">{{authService.getInitials(timesheet.candidate)}}</span>
                      </div>
                      <span>{{timesheet.candidate}}</span>
                    </td>
                    <td class="text-heading font-bold" *ngSwitchCase="'Client'"
                      (click)="permissions?.clients?redirect('/clients', timesheet.client) : openTimesheetDetails(timesheet, timesheet.status, true)">
                      <div class="textWithIcon">
                        <i class="bi bi-people"></i>
                        <span>{{timesheet.client}}</span>
                      </div>
                    </td>
                    <td *ngSwitchCase="'Start Date'" (click)="openTimesheetDetails(timesheet, timesheet.status, true)">
                      <!-- {{dataSharedService.getFormatedDateUTC(timesheet.start_date)}} -->
                      {{dataSharedService.tranformDate(timesheet.start_date)}}
                    </td>
                    <td *ngSwitchCase="'End Date'" (click)="openTimesheetDetails(timesheet, timesheet.status, true)">
                      <!-- {{dataSharedService.getFormatedDateUTC(timesheet.end_date)}} -->
                      {{dataSharedService.tranformDate(timesheet.end_date)}}
                    </td>
                    <td *ngSwitchCase="'Status'" (click)="openTimesheetDetails(timesheet, timesheet.status, true)">
                      <div class="sheetStatus bg-opacity-20" [ngClass]="
                      timesheet.status === STATUS.PENDING ? 'bg-warning text-warning':
                      timesheet.status === STATUS.APPROVED ? 'bg-success text-success':
                      timesheet.status === STATUS.REJECTED ? 'bg-tertiary text-tertiary':
                      timesheet.status === STATUS.SUBMITTED ? 'bg-primary text-primary' : ''">
                        <i class="bi" [ngClass]="
                      timesheet.status === STATUS.PENDING ? 'bi-hourglass-split':
                      timesheet.status === STATUS.APPROVED ? 'bi-check-circle-fill':
                      timesheet.status === STATUS.REJECTED ? 'bi-x-circle-fill':
                      timesheet.status === STATUS.SUBMITTED ? 'bi-stopwatch-fill' : ''"></i>
                        <span class="d-flex align-items-center">{{timesheet.status}}</span>
                      </div>
                    </td>
                    <ng-container *ngIf="agency_uuid === AGENCY.HYPHEN">
                      <ng-container *ngSwitchCase="'Work Duration'">
                        <td (click)="openTimesheetDetails(timesheet, timesheet.status, false)">
                          <div id="hyphenHoursAndDays">
                            <span>{{timesheet?.fields[0]?.days ? timesheet.fields[0].days :''}}</span>
                            <span>{{timesheet?.fields[0]?.hours ? timesheet.fields[0].hours :''}}</span>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container *ngSwitchCase="'Time Unit'">
                        <td (click)="openTimesheetDetails(timesheet, timesheet.status, false)">
                          <div id="hyphenHoursAndDays">
                            <span>{{timesheet?.fields[1]?.days ? timesheet.fields[1].days :''}}</span>
                            <span>{{timesheet?.fields[1]?.hours ? timesheet.fields[1].hours :''}}</span>
                          </div>
                        </td>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Hours'">
                      <td td_switchcase="Hours" (click)="openTimesheetDetails(timesheet, timesheet.status, false)">
                        {{timesheet.total_hours}}
                      </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Days'">
                      <td td_switchcase="Days" (click)="openTimesheetDetails(timesheet, timesheet.status, false)">
                        {{timesheet.total_days}}
                      </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Start Time'">
                      <td td_switchcase="Start Time" (click)="openTimesheetDetails(timesheet, timesheet.status, false)">
                        {{timesheet.total_hours}}
                      </td>
                    </ng-container>
                  </ng-container>
                  <td style="text-align: end;">
                    <mat-select style="width: 0;" #singleTimesheetOptions class="singleTimesheetOptionsSelect"
                      panelClass="matRole" role="button">
                      <mat-option
                        *ngIf="timesheet.status === STATUS.SUBMITTED && (user.role_uuid === ROLES.AGENCY || user.role_uuid === ROLES.CLIENT)">
                        <a class="dropdown-item btn" (click)="onApprove(timesheet.uuid)">
                          Approve Timesheet
                        </a>
                      </mat-option>
                      <mat-option
                        *ngIf="timesheet.status === STATUS.SUBMITTED && (user.role_uuid === ROLES.AGENCY || user.role_uuid === ROLES.CLIENT)">
                        <a class="dropdown-item btn" (click)="onReject(timesheet.uuid)">
                          Reject Timesheet
                        </a>
                      </mat-option>
                      <mat-option>
                        <a class="dropdown-item btn" (click)="openTimesheetDetails(timesheet, timesheet.status, true)">
                          View Timesheet
                        </a>
                      </mat-option>

                      <mat-option
                        *ngIf="((timesheet.status !== STATUS.APPROVED) && (user.role_uuid === ROLES.CANDIDATE || user.role_uuid === ROLES.AGENCY))">
                        <a class="dropdown-item btn" (click)="openTimesheetDetails(timesheet, timesheet.status, false)">
                          Edit Timesheet
                        </a>
                      </mat-option>

                      <mat-option (click)="deleteTimesheet(timesheet.uuid)"
                        *ngIf="(timesheet.status !== STATUS.APPROVED && (user.role_uuid === ROLES.CANDIDATE || user.role_uuid === ROLES.AGENCY))">
                        <a class="dropdown-item btn">
                          Delete Timesheet
                        </a>
                      </mat-option>
                      <mat-option
                        *ngIf="timesheet.status === STATUS.SUBMITTED && (user.role_uuid === ROLES.AGENCY || user.role_uuid === ROLES.CLIENT)">
                        <a class="dropdown-item btn" (click)="openTimesheetApprovalLinks(timesheet.uuid)">
                          Timesheet Approval Link
                        </a>
                      </mat-option>
                      <mat-option
                        *ngIf="timesheet.status === STATUS.APPROVED && (user.role_uuid === ROLES.AGENCY || user.role_uuid === ROLES.CLIENT)">
                        <a class="dropdown-item btn" (click)="openTimesheetViewLink(timesheet.uuid)">
                          Timesheet View Link
                        </a>
                      </mat-option>
                      <mat-option
                        *ngIf="timesheet.status === STATUS.APPROVED && (user.role_uuid === ROLES.CANDIDATE)">
                        <a class="dropdown-item btn" (click)="sendTimesheetInvoice(timesheet.uuid)">
                          Send Invoice
                        </a>
                      </mat-option>
                    </mat-select>
                    <a class="text-muted" role="button" (click)="singleTimesheetOptions.open()">
                      <i class="bi bi-three-dots-vertical"></i>
                    </a>
                  </td>
                </tr>
              </ng-container>
          </table>
        </div>

        <app-api-paginator *ngIf="totalTimesheetCount" [totalCount]="totalTimesheetCount"
          (onStepChange)="onStepChange($event)"></app-api-paginator>

        <div class="modal fade" id="timesheetModal" tabindex="-1">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Add Timesheet</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="table-responsive">
                  <table class="table table-hover table-nowrap">
                    <thead>
                      <tr>
                        <th scope="col">Job</th>
                        <th scope="col">Date</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Time</th>
                        <th scope="col">Counterpart Entity</th>
                        <th scope="col">Counterpart Person</th>
                        <th scope="col">Work Summary</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th><a href="#">Data Operator</a></th>
                        <td>2022-02-01</td>
                        <td>
                          <select class="form-select" id="validationCustom04" required="">
                            <option selected="" disabled="" value="0.5">0.5</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td>
                          <select class="form-select" id="validationCustom04" required="">
                            <option selected="" disabled="" value="4">4</option>
                            <option value="8">8</option>
                          </select>
                        </td>
                        <td><input type="text" class="form-control" id="validationCustom03" required=""></td>
                        <td><input type="text" class="form-control" id="validationCustom03" required=""></td>
                        <td><textarea class="form-control" placeholder="Work Summary" id="floatingTextarea"
                            style="height: 100px;"></textarea></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>