<div class="modal-header">
  <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
  <h4 class="modal-title">Add New Candidate</h4>
  <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>

<form class="" [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
      <div class="row g-3">
        <div class="col-md-6">
          <label for="form-label">First Name <span class="text-danger">*</span></label>
          <input type="text" class="form-control" formControlName="first_name"
            [id]="userForm.controls.first_name.errors && userForm.controls.first_name.touched?'invalidField':''">
        </div>
        <div class="col-md-6">
        <label for="form-label">Last Name <span class="text-danger">*</span></label>
        <input type="text" class="form-control" formControlName="last_name"
          [id]="userForm.controls.last_name.errors && userForm.controls.last_name.touched?'invalidField':''">
      </div>
      </div>
      <div class="row g-3">
        <div class="col-md-6">
          <label for="form-label">Email <span class="text-danger">*</span></label>
          <input type="text" class="form-control" formControlName="email"
            [id]="userForm.controls.email.errors && userForm.controls.email.touched?'invalidField':''">
        </div>
      </div>
      <div class="accordion mt-5" id="additionalInfo">
        <!-- Address details -->
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button d-flex justify-content-between" type="button" (click)="toggleSection('addressDetails')" [class.collapsed]="collapsedSections['addressDetails']">
              <b>Address Details (Required)</b>
            </button>
          </h2>
          <div [ngClass]="{'collapse': collapsedSections['addressDetails'], 'show': !collapsedSections['addressDetails']}" id="addressDetails">
            <div class="accordion-body">
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="form-label">Address 1 <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" formControlName="address_1"
                    [id]="userForm.controls.address_1.errors && userForm.controls.address_1.touched?'invalidField':''">
                </div>
                <div class="col-md-6">
                  <label for="form-label">Address 2</label>
                  <input type="text" class="form-control" formControlName="address_2"
                    [id]="''">
                </div>
              </div>
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="form-label">Post Code</label>
                  <input type="text" class="form-control" formControlName="post_zip_code"
                    [id]="''">
                </div>
                <div class="col-md-6">
                  <label for="form-label">Country</label>
                  <input type="text" class="form-control" formControlName="country"
                    [id]="''">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- VAT details-->
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button d-flex justify-content-between" type="button" (click)="toggleSection('vatDetails')" [class.collapsed]="collapsedSections['vatDetails']">
              <b>VAT Details</b>
            </button>
          </h2>
          <div [ngClass]="{'collapse': collapsedSections['vatDetails'], 'show': !collapsedSections['vatDetails']}" id="vatDetails">
            <div class="accordion-body">
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="form-label">Is Vat Applicable ?</label>
                  <mat-select class="dropdown" type="text"  
                  class="form-control" formControlName="is_vat_applicable">
                    <mat-option [value]="0">No</mat-option>
                    <mat-option [value]="1">Yes</mat-option>
                  </mat-select>
                </div>
                <div class="col-md-6">
                  <label for="form-label">VAT Code</label>
                  <input type="text" class="form-control" formControlName="vat_code"
                  [id]="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Limited company Information-->
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button d-flex justify-content-between" type="button" (click)="toggleSection('limitedCompanyDetails')" [class.collapsed]="collapsedSections['limitedCompanyDetails']">
              <b>Limited Company Details</b>
            </button>
          </h2>
          <div [ngClass]="{'collapse': collapsedSections['limitedCompanyDetails'], 'show': !collapsedSections['limitedCompanyDetails']}" id="limitedCompanyDetails">
            <div class="accordion-body">
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="form-label">Limited Company/Umbrella name</label>
                  <input type="text" class="form-control" formControlName="limited_company_name"
                  [id]="">
                </div>
                <div class="col-md-6">
                  <label for="form-label">Limited Company/Umbrella Address</label>
                  <textarea class="form-control" formControlName="limited_company_address"
                  [id]=""></textarea>
                </div>
              </div>
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="form-label">Bank Name</label>
                  <input type="text" class="form-control" formControlName="bank_name"
                    [id]="">
                </div>
                <div class="col-md-6">
                  <label for="form-label">Bank Address</label>
                  <textarea class="form-control" formControlName="limited_company_bank_address"
                  [id]=""></textarea>
                </div>
                
              </div>
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="form-label">Sort Code</label>
                  <input type="text" class="form-control" formControlName="limited_company_sort_code"
                    [id]="">
                </div>
                <div class="col-md-6">
                  <label for="form-label">Account Number</label>
                  <input type="text" class="form-control" formControlName="limited_company_account_number"
                    [id]="">
                </div>
              </div>
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="form-label">SWIFT/BIC Number</label>
                  <input type="text" class="form-control" formControlName="limited_company_swift_code"
                    [id]="">
                </div>
                <div class="col-md-6">
                  <label for="form-label">Email</label>
                  <input type="text" class="form-control" formControlName="limited_company_email"
                    [id]="">
                </div>
              </div>
            </div>
          </div>
        </div>
         <!-- Additional Information-->
         <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button d-flex justify-content-between" type="button" (click)="toggleSection('additionalDetails')" [class.collapsed]="collapsedSections['additionalDetails']">
              <b>Additional Details</b>
            </button>
          </h2>
          <div [ngClass]="{'collapse': collapsedSections['additionalDetails'], 'show': !collapsedSections['additionalDetails']}" id="additionalDetails">
            <div class="accordion-body">
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="form-label">Identifier</label>
                  <input type="text" class="form-control" formControlName="identifier"
                    [id]="''">
                </div>
                <div class="col-md-6">
                  <label for="form-label">SSN/NI</label>
                  <input type="text" class="form-control" formControlName="ssn"
                    [id]="''">
                </div>
              </div>
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="form-label">PO Number</label>
                  <input type="text" class="form-control" formControlName="po_number"
                    [id]="''">
                </div>
                <div class="col-md-6">
                  <label for="form-label">Registration Number</label>
                  <input type="text" class="form-control" formControlName="registration_number"
                  [id]="">
                </div>
              </div>
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="form-label">Payment Terms</label>
                  <mat-select class="dropdown" type="text"  
                  class="form-control" formControlName="payment_terms">
                    <mat-option *ngFor="let term of ['7', '15', '30', '45', '60', '90']" [value]="term">{{term}} Days</mat-option>
                  </mat-select>
                </div>
                <div class="col-md-6">
                  <label for="form-label">Currency</label>
                  <mat-select class="dropdown" type="text"  
                  class="form-control" formControlName="currency_format">
                    <mat-option *ngFor="let currency_format of dataSharedService.currencyFormatList" [value]="currency_format">{{currency_format}}</mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
     
      <div class="row g-3">
        <div class="error" *ngIf="error">
          <span>{{error}}</span>
        </div>
      </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-sm mx-2 btn-primary" [disabled]="!userForm.valid">Save</button>
  </div>
</form>



<!-- <form class="" [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
      <div class="row g-3">
      <div class="col-md-6">
        <label for="form-label">First Name</label>
        <input type="text" class="form-control" formControlName="first_name"
          [id]="userForm.controls.first_name.errors && userForm.controls.first_name.touched?'invalidField':''">
      </div>
      <div class="col-md-6">
        <label for="form-label">Last Name</label>
        <input type="text" class="form-control" formControlName="last_name"
          [id]="userForm.controls.last_name.errors && userForm.controls.last_name.touched?'invalidField':''">
      </div>
      </div>
      <div class="row g-3">
      <div class="col-md-6">
        <label for="form-label">Email</label>
        <input type="text" class="form-control" formControlName="email"
          [id]="userForm.controls.email.errors && userForm.controls.email.touched?'invalidField':''">
      </div>
      <div class="col-md-6">
        <label for="form-label">Identifier</label>
        <input type="text" class="form-control" formControlName="identifier"
          [id]="''">
      </div>
      </div>
      <div class="row g-3">
      <div class="col-md-6">
        <label for="form-label">SSN/NI</label>
        <input type="text" class="form-control" formControlName="ssn"
          [id]="''">
      </div>
      <div class="col-md-6">
        <label for="form-label">PO Number</label>
        <input type="text" class="form-control" formControlName="po_number"
          [id]="''">
      </div>
      <div class="row g-3">
        <div class="col-md-6">
          <label for="form-label">Address 1</label>
          <input type="text" class="form-control" formControlName="address_1"
            [id]="userForm.controls.address_1.errors && userForm.controls.address_1.touched?'invalidField':''">
        </div>
        <div class="col-md-6">
          <label for="form-label">Address 2</label>
          <input type="text" class="form-control" formControlName="address_2"
            [id]="''">
        </div>
      </div>
      <div class="row g-3">
        <div class="col-md-6">
          <label for="form-label">Post Code</label>
          <input type="text" class="form-control" formControlName="post_zip_code"
            [id]="''">
        </div>
        <div class="col-md-6">
          <label for="form-label">Country</label>
          <input type="text" class="form-control" formControlName="country"
            [id]="''">
        </div>
      </div>
      <div class="row g-3">
        <div class="col-md-6">
          <label for="form-label">Bank Name</label>
          <input type="text" class="form-control" formControlName="bank_name"
            [id]="">
        </div>
        <div class="col-md-6">
          <label for="form-label">Account Number</label>
          <input type="text" class="form-control" formControlName="account_number"
            [id]="">
        </div>
      </div>
      <div class="row g-3">
        <div class="col-md-6">
          <label for="form-label">Payment Terms</label>
          <mat-select class="dropdown" type="text"  
          class="form-control" formControlName="payment_terms">
            <mat-option [value]="'7'">7 Days</mat-option>
            <mat-option [value]="'15'">15 Days</mat-option>
            <mat-option [value]="'30'">30 Days</mat-option>
            <mat-option [value]="'45'">45 Days</mat-option>
            <mat-option [value]="'60'">60 Days</mat-option>
            <mat-option [value]="'90'">90 Days</mat-option>
          </mat-select>
        </div>
        <div class="col-md-6">
          <label for="form-label">VAT Code</label>
          <input type="text" class="form-control" formControlName="vat_code"
          [id]="">
        </div>
      </div>
      <div class="row g-3">
        <div class="col-md-6">
          <label for="form-label">Registration Number</label>
          <input type="text" class="form-control" formControlName="registration_number"
          [id]="">
        </div>
      </div>
      <div class="row g-3">
        <div class="col-md-6">
          <label for="form-label">Limited Company/Umbrella name</label>
          <input type="text" class="form-control" formControlName="limited_company_name"
          [id]="">
        </div>
        <div class="col-md-6">
          <label for="form-label">Limited Company/Umbrella Address</label>
          <textarea class="form-control" formControlName="limited_company_address"
          [id]=""></textarea>
        </div>
      </div>
      <div class="error" *ngIf="error">
        <span>{{error}}</span>
      </div>
      </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-sm mx-2 btn-primary" [disabled]="!userForm.valid">Save</button>
  </div>
</form> -->