<div class="modal-header p-5">
    <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
    <h3 class="modal-title">Invoice Details</h3>
    <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>
<body>
    <div class="modal-body">
        <div class="invoice-container">
            <div class="invoice-header">
                <img src="{{dataSharedService.agencyLogo}}" alt="Company Logo">
            </div>
            <br>
                <div *ngIf="invoiceType == 'client'">{{invoice.agency_address[0].address_1}},{{invoice.agency_address[0].address_2}},{{invoice.agency_address[0].country}}, {{invoice.agency_address[0].post_zip_code}}</div>
                <div *ngIf="invoiceType == 'contractor'">{{invoice.limited_company}}, {{invoice.limited_company_address}}</div>
            <hr>
            <div class="invoice-details">
                <table>
                    <tr>
                        <td *ngIf="invoiceType == 'client'">
                            <br>{{invoice.client}}
                            <br> {{invoice.client_address[0].address_1}},
                            <br> {{invoice.client_address[0].address_2}},
                            <br> {{invoice.client_address[0].country}}, {{invoice.client_address[0].post_zip_code}}
                        </td>
                        <td *ngIf="invoiceType == 'contractor'">
                            <br> {{invoice.agency_address[0].address_1}},
                            <br> {{invoice.agency_address[0].address_2}},
                            <br> {{invoice.agency_address[0].country}}, {{invoice.agency_address[0].post_zip_code}}
                            <!-- <br>{{invoice.candidate}}
                            <br> {{invoice.candidate_address[0].address_1}},
                            <br> {{invoice.candidate_address[0].address_2}},
                            <br> {{invoice.candidate_address[0].country}}, {{invoice.candidate_address[0].post_zip_code}} -->
                        </td>
                        <td>
                            <p><strong>Invoice Number:</strong> {{invoice.invoice_number}}</p>
                            <p><strong>Date:</strong> {{invoice.invoice_date}}</p>
                        </td>
                    </tr>
                </table>
            </div>
        
            <div class="invoice-summary">
                <table>
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Worker</th>
                            <th *ngIf="candidateTrackingHrs != 2">Hours</th>
                            <th *ngIf="candidateTrackingHrs == 2">Days</th>
                            <th>Rate</th>
                            <th *ngIf="invoiceType == 'client'">Total Bill</th>
                            <th *ngIf="invoiceType == 'contractor'">Total Pay</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr *ngFor="let invoiceDetails of invoice.invoice_details">
                            <td>{{invoiceDetails.description}}</td>
                            <td>{{invoice.candidate}}</td>
                            <td *ngIf="candidateTrackingHrs != 2">{{invoiceDetails.hours}}</td>
                            <td *ngIf="candidateTrackingHrs == 2">{{invoiceDetails.total_days}}</td>
                            <td *ngIf="invoiceType == 'client'">{{invoice.client_currency_format}} {{invoiceDetails.bill_rate}}</td>
                            <td *ngIf="invoiceType == 'contractor'">{{invoice.candidate_currency_format}} {{invoiceDetails.pay_rate}}</td>
                            <td *ngIf="invoiceType == 'client'">{{invoice.client_currency_format}} {{invoiceDetails.total_bill}}</td>
                            <td *ngIf="invoiceType == 'contractor'">{{invoice.candidate_currency_format}} {{invoiceDetails.total_pay}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4">Subtotal</td>
                            <td *ngIf="invoiceType == 'client'">{{invoice.client_currency_format}} {{invoice.bill_amount}}</td>
                            <td *ngIf="invoiceType == 'contractor'">{{invoice.candidate_currency_format}} {{invoice.pay_amount}}</td>
                        </tr>
                        <tr>
                            <td colspan="4">VAT (20%)</td>
                            <td *ngIf="invoiceType == 'client'">{{invoice.client_currency_format}} {{invoice.bill_amount * 0.2}}</td>
                            <td *ngIf="invoiceType == 'contractor'">{{invoice.candidate_currency_format}} {{invoice.pay_amount * 0.2 }}</td>
                        </tr>
                        <tr>
                            <td colspan="4" class="total">Total</td>
                            <td *ngIf="invoiceType == 'client'">{{invoice.client_currency_format}} {{invoice.bill_amount + invoice.bill_amount * 0.2}}</td>
                            <td *ngIf="invoiceType == 'contractor'">{{invoice.candidate_currency_format}} {{invoice.pay_amount + invoice.pay_amount * 0.2}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div *ngIf="invoice.client_sent != 'Sent' && invoiceType == 'client'" class="col-sm col-12 mt-4 text-md-center d-flex justify-content-md-center">
            <!-- <button class="btn btn-sm btn-primary me-2 mt-5" type="button" (click)="onUpdate(invoiceType)">Update</button> -->
            <button class="btn btn-sm btn-success me-2 mt-5" type="button" (click)="onSend(invoiceType, invoice.uuid)" >Send</button>
        </div>
        <div *ngIf="invoice.contractor_sent != 'Sent' && invoiceType == 'contractor'" class="col-sm col-12 mt-4 text-md-center d-flex justify-content-md-center">
            <!-- <button class="btn btn-sm btn-primary me-2 mt-5" type="button" (click)="onUpdate(invoiceType)">Update</button> -->
            <button class="btn btn-sm btn-success me-2 mt-5" type="button" (click)="onSend(invoiceType, invoice.uuid)" >Send</button>
        </div>
    </div>
