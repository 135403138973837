<div class="card-body">
    <div class="mb-5">
		<h4 class="mb-1">Bank Details</h4>
	</div>
	<form [formGroup]="bankForm">
		<div class="vstack gap-5">
			<div class="row mt-1">
				<div class="col-md-6">
					<div>
						<label class="text-sm text-muted">Name</label> 
						<input type="text"  class="form-control" formControlName="bank_name">
					</div>
				</div>
				<div class="col-md-6">
					<div>
						<label class="text-sm text-muted">Sort Code</label> 
						<input type="text"  class="form-control" formControlName="sort_code">
					</div>
				</div>
			</div>
			<div class="row mt-1">
				<div class="col-md-6">
					<div>
						<label class="text-sm text-muted">Account Number</label> 
						<input type="text"  class="form-control" formControlName="account_number">
					</div>
				</div>
				<div class="col-md-6">
					<div>
						<label class="text-sm text-muted">Swift/BIC Number</label> 
						<input type="text"  class="form-control" formControlName="swift_bic_number">
					</div>
				</div>
			</div>
			<div class="row mt-1">
				<div class="col-md-12">
					<div>
						<label class="text-sm text-muted">Address</label> 
						<textarea class="form-control" formControlName="address"
            			[id]=""></textarea>
					</div>
				</div>
			</div>
		</div>
	</form>
    
    <div class="hstack gap-2 justify-content-end mt-5"><button class="btn btn-sm btn-primary" (click)="saveAgencyBankDetails()">Save</button></div>
</div>