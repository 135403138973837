import { Component, NgZone, OnInit, Inject, Input } from '@angular/core';

import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';
import 'moment/locale/pt-br';

import { apiConstant, roleConstant, statusConstant } from '../app.constant';
import { AuthenticationService } from '../services/authentication.service';
import { TimesheetService } from '../services/timesheet.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertModal, auditTrailHeader, SuccessModal } from '../app.model';
import { AlertModalComponent } from '../components/alert-modal/alert-modal.component';
import { DataSharedService } from '../services/data-shared.service';
import { SwiperOptions } from 'swiper';
import { SuccessModalComponent } from '../components/success-modal/success-modal.component';
import { CommentsService } from '../services/comments.service';
import { AttachmentsService } from '../services/attachments.service';
import { JobAssignmentService } from '../services/job-assignment.service';

@Component({
  selector: 'app-timesheet-details',
  templateUrl: './timesheet-details.component.html',
  styleUrls: ['./timesheet-details.component.css'],
})

export class TimesheetDetailsComponent implements OnInit {
  @Input() timesheetId: any;
  @Input() readonly: any = false;
  @Input() status: any;
  @Input() createTimesheetsArr: any;
  @Input() createTimesheetForm: any;
  @Input() from!: string;
  @Input() start_date: any;
  @Input() end_date: any;
  @Input() jobName!: string;
  @Input() timesheetForm: any;
  @Input() timesheetDetails: any = [];
  @Input() fields: any = [];

  timesheet: any
  error: any = '';
  arFlag: boolean = false;
  disabled = false;
  public ROLES = roleConstant;
  public STATUS = statusConstant;
  public API_CONSTANT = apiConstant;
  public user: any;
  loading: boolean = false;
  tab: string = 'timesheetDetails';
  auditTrailActivities: any = [];
  attachmentsArr: any = [];
  commentsList: any = [];
  auditTrail!: auditTrailHeader;
  selected = 0;
  index = 0;
  timesheet_total!: number;

  accordion_panel_ids: any = [];
  accordion_active_ids: any = [];

  config: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 10,
    slidesPerView: 6,
  };

  constructor(
    public activeModal: NgbActiveModal,
    private timesheetService: TimesheetService,
    private commentService: CommentsService,
    private attachmentsService: AttachmentsService,
    private authService: AuthenticationService,
    private dialogRef: MatDialogRef<TimesheetDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ngZone: NgZone,

    private modalService: NgbModal,
    private activeModalService: NgbActiveModal,

    public dataSharedService: DataSharedService,
    private jobService: JobAssignmentService,
    public datePipe: DatePipe,

  ) {
    this.user = this.authService.userValue;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    const user: any = this.authService.userValue;

    if (this.from === 'create-timesheet') {

      this.auditTrail = {
        title: this.jobName,
        // subTitle: `${this.dataSharedService.getFormatedDateUTC(this.start_date)} - ${this.dataSharedService.getFormatedDateUTC(this.end_date)}`,
        subTitle: `${this.dataSharedService.tranformDate(this.start_date)} - ${this.dataSharedService.tranformDate(this.end_date)}`,
        icon: 'bi bi-briefcase'
      }

      let accordion_panel_first_id: any = [];
      await this.timesheetDetails.timesheet_details.forEach((formData: any, index: number) => {
        if (index == 0) {
          accordion_panel_first_id.push(`timsheet-ngbaccordion-panel-${index}`);
        }
      });
      this.accordion_active_ids = accordion_panel_first_id;


      this.readonly = true;
      this.loading = false

    } else {

      if (this.timesheet) {
        this.getAuditTrailData();
      }

      if (user.role_uuid === this.ROLES.AGENCY || user.role_uuid === this.ROLES.CLIENT) {
        this.readonly = true;
        this.disabled = true;
        this.arFlag = this.status === this.STATUS.SUBMITTED ? true : false;
      } else if (user.role_uuid === this.ROLES.CANDIDATE) {
        this.arFlag = false;
        if (this.status === this.STATUS.APPROVED) {
          this.readonly = true;
          this.disabled = true;
        }
      }


      this.tab = this.readonly && this.status === statusConstant.PENDING ? 'auditTrail' : 'timesheetDetails';
      this.timesheetService.getTimesheetDetails(this.timesheetId).subscribe(async timesheetDetails => {

        this.timesheetDetails = timesheetDetails.data;
        this.commentsList = await new Promise((resolve, reject) => this.commentService.getComments(this.timesheet.uuid, this.API_CONSTANT.TIMESHEET_COMMENTS, 'timesheet_uuid').subscribe((res: any) => resolve(res.data), err => resolve([])))

        this.auditTrailActivities = await new Promise((resolve, reject) => this.timesheetService.getAuditTrail('timesheet', this.timesheet.uuid).subscribe((res: any) => resolve(res.data), err => resolve([])))

        this.attachmentsArr = await new Promise((resolve, reject) => this.attachmentsService.getAttachments(this.timesheet.uuid).subscribe((res: any) => resolve(res), err => resolve([])))


        // this.fields = this.timesheetDetails.timesheet_details[0];
        // if (this.fields?.field_values) {
        //   this.fields.field_values = this.fields.field_values.sort((a: any, b: any) => a.order - b.order);
        // }

        this.fields = this.timesheetDetails.timesheet_form_details;
        this.fields = this.fields[0];
        this.fields.field_values = this.fields.fields.sort((a: any, b: any) => a.order - b.order);


        const form: any = {};
        let timesheet_total: number = 0;
        let timesheet_total_type: string = "Total Hrs";
        let timesheet_total_type_suffix: string = "Hours";

        let accordion_panel_ids: any = [];
        let accordion_panel_first_id: any = [];
        this.timesheetForm = new FormGroup(form);
        await this.timesheetDetails.timesheet_details.forEach((formData: any, index: number) => {

          let timesheet_timeline: any = {};
          this.fields?.field_values.forEach((element: any) => {
            if (element.field_type !== 'static') {
              form[element.label + '_' + index] = new FormControl('', element.is_required ? [Validators.required] : []);
              const field = formData?.field_values.find((x: any) => x.uuid === element.uuid);

              this.timesheetForm.patchValue({
                [element.label + '_' + index]: field?.values,
              });

              // New code Start
              if (field.type === 'number') {
                timesheet_timeline = {
                  ...timesheet_timeline,
                  hours: field?.values
                }
              }

              if (field.type === 'time_range') {
                if (field?.label === "Start Time") {
                  timesheet_timeline = {
                    ...timesheet_timeline,
                    start_date: field?.values
                  }
                }
                if (field?.label === "End Time") {
                  timesheet_timeline = {
                    ...timesheet_timeline,
                    end_date: field?.values
                  }
                }
              }

              if (field.type === 'dropdown') {
                timesheet_timeline = {
                  ...timesheet_timeline,
                  days: field?.values
                }
              }
              // New code End

            }
          });

          let timesheet_display: string = "";
          if (timesheet_timeline?.hours) {
            timesheet_display = `${timesheet_timeline?.hours} Hours`;

            timesheet_total += Number(parseFloat(timesheet_timeline?.hours));
          }

          if (timesheet_timeline?.start_date) {
            timesheet_display = `${timesheet_timeline?.start_date} - ${timesheet_timeline?.end_date}`;

            const start_date = moment(`1970-1-1 ${timesheet_timeline?.start_date}`);
            const end_date = moment(`1970-1-1 ${timesheet_timeline?.end_date}`);
            let duration = moment.duration(end_date.diff(start_date));
            let duration_mint: any = (duration.minutes() / 60) % 30;
            let timesheet_timeline_hours: any = duration.hours() + parseFloat(duration_mint);
            timesheet_total += Number(parseFloat(timesheet_timeline_hours));
          }

          if (timesheet_timeline?.days) {
            let timesheet_timeline_day: number = 0;
            timesheet_timeline_day = (timesheet_timeline?.days === 'Full Day') ? parseFloat('1') : timesheet_timeline_day;
            timesheet_timeline_day = (timesheet_timeline?.days === 'Half Day') ? parseFloat('0.5') : timesheet_timeline_day;

            timesheet_total_type = "Total Days";
            timesheet_total_type_suffix = "days";
            timesheet_total += Number(timesheet_timeline_day);
          }

          accordion_panel_ids.push(`timsheet-ngbaccordion-panel-${index}`);
          if (index == 0) {
            accordion_panel_first_id.push(`timsheet-ngbaccordion-panel-${index}`);
          }

          this.timesheetDetails.timesheet_details[index]["timesheet_display"] = timesheet_display;
        });

        this.loading = false;
        this.accordion_panel_ids = accordion_panel_ids;
        this.accordion_active_ids = accordion_panel_first_id;
        this.timesheetDetails.timesheet_details["timesheet_total"] = timesheet_total;
        this.timesheetDetails.timesheet_details["timesheet_total_type"] = timesheet_total_type;
        this.timesheetDetails.timesheet_details["timesheet_total_type_suffix"] = timesheet_total_type_suffix;
      });
    }
  }

  get totalDays() {
    let timesheet_total = 0;
    this.timesheetDetails.timesheet_details.forEach((formData: any, form_index: number) => {
      this.fields.field_values.forEach((element: any) => {

        if (element.field_type !== 'static' && element.label === 'Hours') {
          timesheet_total += Number(this.timesheetForm.controls[element.label + '_' + form_index].value)
        }
        if (element.field_type !== 'static' && element.label === 'Start Time') {

          let startTime = this.timesheetForm.controls[element.label + '_' + form_index].value;
          let endTime = this.timesheetForm.controls['End Time_' + form_index].value;

          if (startTime && endTime) {
            if (endTime < startTime) {
              timesheet_total -= Number(this.getHrsCountBetweenTwoTime(startTime, endTime))
            } else {
              timesheet_total += Number(this.getHrsCountBetweenTwoTime(startTime, endTime))
            }
          }
        }

        if (element.field_type !== 'static' && element.label === 'Days') {
          let dayType = this.timesheetForm.controls[element.label + '_' + form_index].value;
          console.log(dayType);
          if (dayType != "" && dayType != null && dayType != "undefined") {
            timesheet_total += (dayType == 'Full Day' ? 1 : 0.5);
          }
        }
      });
    });
    return timesheet_total;
  }

  getHrsCountBetweenTwoTime(startTime: string, endTime: string) {
    var inputJSON = {
      "start_date": "2023-01-10 " + startTime,
      "end_date": "2023-01-10 " + endTime
    };
    var startDate = new Date(inputJSON.start_date);
    var endDate = new Date(inputJSON.end_date);

    var diff = (endDate.getTime() - startDate.getTime()) / 1000;

    diff /= (60 * 60);

    return Math.abs(Math.round(diff));
  }

  changeTimesheetMode() {
    if (
      (this.timesheet.status !== this.STATUS.APPROVED || this.timesheet.status !== this.STATUS.REJECTED)
      && (this.user.role_uuid === this.ROLES.AGENCY || this.user.role_uuid === this.ROLES.CANDIDATE)
    ) {
      this.readonly = !this.readonly;
      this.disabled = !this.disabled;

      if (this.readonly) {
        this.accordion_active_ids = [];
      } else {
        this.accordion_active_ids = this.accordion_panel_ids;
      }
    }
  }

  showAllAccordionItems(acc: NgbAccordion) {
    if (acc.activeIds.length) {
      acc.activeIds = [];
    } else {
      acc.activeIds = this.accordion_panel_ids;
    }
  }

  onCommentEnter(newComments: any) {
    this.commentsList = newComments
  }

  onNewAttachment(onNewAttachment: any) {
    this.attachmentsArr = onNewAttachment
  }

  getAuditTrailData() {
    this.auditTrail = {
      title: this.timesheet.job,
      //subTitle: `${this.dataSharedService.getFormatedDateUTC(this.timesheet.start_date)} - ${this.dataSharedService.getFormatedDateUTC(this.timesheet.end_date)}`,
      subTitle: `${this.dataSharedService.tranformDate(this.timesheet.start_date)} - ${this.dataSharedService.tranformDate(this.timesheet.end_date)}`,
      icon: 'bi bi-briefcase'
    }
  }

  onSelection(timesheet: any, idx: number) {
    this.selected = timesheet;
    this.index = idx
  }

  onClick(filed: any) {
    console.table(filed);
  }

  onBlurField(fieldName: string) {
    setTimeout(() => {
      this.timesheetForm.controls[fieldName].focused = false;
    }, 500);
  }

  copyValues(fieldLabel: string, fieldName: string, idx: number) {
    const fieldValue = this.timesheetForm.controls[fieldName].value;
    this.timesheetDetails.timesheet_details.forEach(
      (element: any, i: number) => {
        if (i >= idx) {
          this.timesheetForm.patchValue({
            [fieldLabel + '_' + i]: fieldValue,
          });
        }
      }
    );
  }

  onReject() {
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      backdrop: false,
      modalDialogClass: 'alert-modal modal-dialog-centered',
    });
    let data: AlertModal = {
      message: 'Are you sure you want to reject the Timesheet?',
      yesAction: 'Reject',
      noAction: 'Cancel',
      yesActionColor: 'red',
      noActionColor: 'transparent',
      image: 'assets/img/reject-confirmation-icon.png'
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.timesheetService.rejectTimeSheet(this.timesheetId).subscribe(
          (res) => {
            console.log(res);
            this.activeModalService.close({ refresh: true });
          },
          (error) => {
            console.error(error);
            this.activeModalService.close({ refresh: true });
          }
        );
      }
    });
  }

  onApprove() {
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      backdrop: false,
      modalDialogClass: 'alert-modal modal-dialog-centered',
    });
    let data: AlertModal = {
      message: 'Are you sure you want to approve the Timesheet?',
      yesAction: 'Approve',
      noAction: 'Cancel',
      yesActionColor: '#02cc89',
      noActionColor: 'transparent',
      image: 'assets/img/approve-confirmation-icon.png'
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.timesheetService.approveTimeSheet(this.timesheetId).subscribe(
          (res) => {
            console.log(res);
            this.activeModalService.close({ refresh: true });
          },
          (error) => {
            console.error(error);
            this.activeModalService.close({ refresh: true });
          }
        );
      }
    });
  }

  isValid() {
    let isValid: boolean = true;
    this.timesheetDetails?.timesheet_details.forEach((element: any, i: number) => {
      this.fields.fields.forEach((element: any) => {
        if (element.field_type !== 'static') {
          if (!this.timesheetForm?.controls[element.label + '_' + i].value) {
            isValid = false;
          }
        }
      });
    })
    return isValid;
  }

  get hasValue() {
    let hasValue = false;
    Object.keys(this.timesheetForm.controls).forEach(element => {
      if (this.timesheetForm.controls[element].value) {
        hasValue = true;
      }
    });
    return hasValue
  }

  close() {
    if (this.readonly) {
      this.ngZone.run(() => {
        this.activeModalService.close();
      });
    } else {
      if (!this.hasValue) {
        this.ngZone.run(() => {
          this.activeModalService.close();
        });
        return
      }
      const modalRef = this.modalService.open(AlertModalComponent, {
        size: 'md',
        backdrop: false,
        modalDialogClass: 'alert-modal modal-dialog-centered',
      });
      let data: AlertModal = {
        message: 'Are you sure you want to discard submission?',
        yesAction: 'Discard',
        noAction: 'Cancel',
        yesActionColor: '#0566EA',
        noActionColor: 'transparent',
      };
      modalRef.componentInstance.data = data;
      modalRef.result.then((data: any) => {
        if (data?.action === 'yes') {
          this.activeModalService.close();
        }
      });
    }
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      backdrop: false,
      modalDialogClass: 'success-modal',
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  submitTimesheet() {
    this.error = '';
    this.timesheetForm.markAllAsTouched();
    if (!this.timesheetForm.valid) {
      return
    }

    const fullForm: any = {}
    fullForm['form_uuid'] = this.timesheetDetails.timesheet_form_details[0].uuid

    fullForm['timesheet_details'] = []
    this.timesheetDetails.timesheet_details.forEach((formData: any, i: number) => {
      const singleForm: any = {};

      singleForm['timesheet_date'] = formData.date;
      singleForm['fields'] = [];
      this.fields.field_values.forEach((field: any) => {
        if (field.field_type !== 'static') {
          singleForm['fields'].push({
            uuid: field.uuid,
            values: this.timesheetForm.controls[field.label + '_' + i]?.value
          })
        }
      });
      fullForm['timesheet_details'].push(singleForm);
    });

    this.timesheetService.updateTimesheet(this.timesheetId, fullForm).subscribe((res) => {
      this.activeModalService.close({ refresh: true });
      this.successModal('Timesheet Successfully Submitted!');
    }, error => {
      this.error = error;
      // this.activeModalService.close({ refresh: true });
    });
  }
}