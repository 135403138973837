<div class="mainContainer card overflow-auto margin mt-4">
    <div class="loader" *ngIf="loading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
    <div class="reminder mb-0 d-flex bg-light justify-content-between flex-column flex-sm-row">
        <h5 class="card-title ps-4 mb-0 tableTitle"> {{totalCount}} Candidate(s)</h5>
        <div class="form-inline ms-auto me-4 d-none d-md-flex hstack card-title ps-4 mb-0">
            <div class="input-group input-group-sm input-group-inline"><span class="input-group-text pe-2"><i class="bi bi-search"></i> </span><input type="email" class="form-control" placeholder="Search Candidates" aria-label="Search" [(ngModel)]="candidateSearch" (keyup)="searchCandidate()">
            </div>
        </div>
    </div>
    <table matSort (matSortChange)="sortData($event)" class=" table table-borderless datatable">
        <thead class="table-light">
            <tr>
                <th mat-sort-header="name" scope="col" class="ps-4">Name</th>
                <th mat-sort-header="email" scope="col">Email</th>
                <th mat-sort-header="email" scope="col">SSN/NI</th>
                <th mat-sort-header="email" scope="col">Identifier</th>
                <th mat-sort-header="created_at" scope="col">Created At</th>
                <th mat-sort-header="action" scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let candidate of allCandidates; let i = index" class="dataColumn">
                <td class="text-heading font-bold ps-4">
                    <div class="textWithIcon">
                        <div class="avatar avatar-sm bg-warning rounded-circle text-white h-7 w-7 me-2"
                            *ngIf="candidate.profile_pic">
                            <img alt="..." src="{{dataSharedService.getImage(candidate.profile_pic)}}">
                        </div>
                        <div *ngIf="!candidate.profile_pic" class="cotractorInitialIcon bg-primary bg-opacity-20 h-7 w-7">
                            <span class="text-primary fs-10x">{{authService.getInitials(candidate.name)}}</span>
                        </div>
                        <span>{{candidate.name}}</span>
                    </div>
                </td>
                <td>{{candidate.email}}</td>
                <td>{{candidate.ssn}}</td>
                <td>{{candidate.identifier}}</td>
                <td>{{dataSharedService.getFormatedDate(candidate.created_at)}}</td>
                <td>
                    <div (click)="resendAccountActivation(candidate.uuid)" style="color: #6b7b93;" class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7" matTooltip="Resend Account Creation Mail" [matTooltipPosition]="'left'">
                        <i class="bi bi-link"></i>
                    </div>
                    <div (click)= "showEmploymentEndDateModal(candidate.uuid)" class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7" matTooltip="Deactivate account" [matTooltipPosition]="'left'">
                        <i class="bi bi-lock"></i>
                    </div>
                    <div (click)="generateAccountCreationLink(candidate.uuid)" style="color: #6b7b93;" class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7" matTooltip="Get Account Creation Link" [matTooltipPosition]="'left'">
                        <i class="bi bi-file-lock"></i>
                    </div>
                    <div (click)="editCandidate(candidate)" style="color: #6b7b93;" class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7" matTooltip="Edit Candidate" [matTooltipPosition]="'left'">
                        <i class="bi bi-pencil"></i>
                    </div>
                </td>
            </tr>
    </table>
    <app-api-paginator *ngIf="totalCount" [totalCount]="totalCount" (onStepChange)="onStepChange($event)">
    </app-api-paginator>
</div>