<div class="mainContainer card overflow-auto margin mt-4">
    <div class="loader" *ngIf="loading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
    <div class="reminder mb-0 d-flex bg-light justify-content-between flex-column flex-sm-row">
        <h5 class="card-title ps-4 mb-0 tableTitle"> {{totalCount}} Assignments(s)</h5>
        <div class="form-inline ms-auto me-4 d-none d-md-flex hstack card-title ps-4 mb-0">
        </div>
    </div>
    <table matSort (matSortChange)="sortData($event)" class=" table table-borderless datatable">
        <thead class="table-light">
            <tr>
                <th mat-sort-header="job" scope="col" class="ps-5">Job</th>
                <th mat-sort-header="client" scope="col">Client</th>
                <th mat-sort-header="start_date" scope="col">Start Date</th>
                <th mat-sort-header="end_date" scope="col">End Date</th>
                <th mat-sort-header="created_at" scope="col">Created At</th>
                <th mat-sort-header="status" scope="col">Status</th>
                <th *ngIf="user?.role_uuid === ROLES?.CANDIDATE" mat-sort-header="action" scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr class="assignmentsRow dataColumn" *ngFor="let jobAssignment of jobAssignments">
                <td *ngIf="user?.role_uuid === ROLES?.CANDIDATE" class="text-heading font-bold ps-5">
                    <div class="textWithIcon">
                        <i class="bi bi-briefcase"></i>
                        <span>{{jobAssignment.job}}</span>
                    </div>
                </td>
                <td *ngIf="user?.role_uuid !== ROLES?.CANDIDATE" class="text-heading font-bold ps-5">
                    <div class="textWithIcon">
                        <i class="bi bi-briefcase"></i>
                        <span>{{jobAssignment.job}}</span>
                    </div>
                </td>
                <td>{{jobAssignment.client}}</td>
                <td>{{dataSharedService.tranformDate(jobAssignment.start_date)}}</td>
                <td>{{dataSharedService.tranformDate(jobAssignment.end_date)}}</td>
                <td>{{dataSharedService.tranformDate(jobAssignment.created_at)}}</td>
                <td>
                    <span *ngIf="jobAssignment.status === 2">Pending</span>
                    <span *ngIf="jobAssignment.status === 3">Details Submitted</span>
                    <span *ngIf="jobAssignment.status === 4" class="text-cursor" (click)="viewRejectReason(jobAssignment.reason)">Rejected</span>
                    <span *ngIf="jobAssignment.status === 5" class="text-cursor" (click)="viewRejectReason(jobAssignment.reason)">Permanent Rejected</span>
                    <span *ngIf="jobAssignment.status === 6">Details Resubmitted</span>
                </td>
                <td *ngIf="user?.role_uuid === ROLES?.CANDIDATE">
                    <button *ngIf="jobAssignment.status === 2"
                        class="btn btn-primary btn-sm bt-square ms-1 px-3 py-1 fs-7 btn-primary"
                        (click)="viewOnBoardingForm(jobAssignment)"
                        matTooltip="Submit Onboarding Details" [matTooltipPosition]="'left'">
                        Submit Onboarding Details
                    </button>
                    <button *ngIf="jobAssignment.status === 3 || jobAssignment.status === 6"
                        class="btn btn-success btn-sm bt-square ms-1 px-3 py-1 fs-7 btn-primary"
                        matTooltip="Once approved by the agency, you can view the job in the Active tab and create a timesheet"                       
                        style="margin-bottom: 0px;">
                        Pending Agency Approval
                    </button>
                    <button *ngIf="jobAssignment.status === 4"
                        class="btn btn-success btn-sm bt-square ms-1 px-3 py-1 fs-7 btn-primary"
                        (click)="viewOnBoardingForm(jobAssignment)"
                        matTooltip="Agency has rejected your onboarding details, you can update your onboarding form details and submit again"
                        [matTooltipPosition]="'left'">
                        Edit Onboarding Details
                    </button>
                    <div *ngIf="(jobAssignment.status === 3 || jobAssignment.status === 4 || jobAssignment.status === 6) && jobAssignment.is_contract_submitted === false && jobAssignment.is_contract_uploaded === true"
                        class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7"
                        (click)="viewOnBoardingForm(jobAssignment, true)"
                        matTooltip="Submit Contract" [matTooltipPosition]="'left'">
                        <i class="bi bi-c-square"></i>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <app-api-paginator *ngIf="totalCount" [totalCount]="totalCount" (onStepChange)="onStepChange($event)">
    </app-api-paginator>
</div>