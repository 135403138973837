import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { JobsComponent } from './jobs/jobs.component';
import { LoginComponent } from './login/login.component';
import { ReportsComponent } from './reports/reports.component';
import { SettingsComponent } from './settings/settings.component';
import { TimesheetsComponent } from './timesheets/timesheets.component';
import { LogoutComponent } from './logout/logout.component';

import { ClientsComponent } from './clients/clients.component';
import { ClientCreateComponent } from './client-create/client-create.component';
import { ClientUpdateComponent } from './client-update/client-update.component';

import { JobAssignmentsComponent } from './job-assignments/job-assignments.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RemindersComponent } from './reminders/reminders.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TimesheetFormsComponent } from './timesheet-forms/timesheet-forms.component';
import { AgenciesComponent } from './agencies/agencies.component';
import { GeneralTabComponent } from './general-tab/general-tab.component';
import { AuthorizationFlowTabComponent } from './authorization-flow-tab/authorization-flow-tab.component';
import { CandidatesComponent } from './candidates/candidates.component';
import { CreateFormComponent } from './components/create-form/create-form.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { TasksListComponent } from './components/tasks-list/tasks-list.component';
import { ClientContactsComponent } from './client-contacts/client-contacts.component';
import { PayBillReportComponent } from './pay-bill-report/pay-bill-report.component';
import { PayrollReportComponent } from './payroll-report/payroll-report.component';
import { ActiveCandidatesComponent } from './components/active-candidates/active-candidates.component';
import { InactiveCandidatesComponent } from './components/inactive-candidates/inactive-candidates.component';
import { ActiveJobsComponent } from './components/active-jobs/active-jobs.component';
import { InactiveJobsComponent } from './components/inactive-jobs/inactive-jobs.component';
import { ActiveAssignmentsComponent } from './components/active-assignments/active-assignments.component';
import { InactiveAssignmentsComponent } from './components/inactive-assignments/inactive-assignments.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { PayInvoicesComponent } from './components/pay-invoices/pay-invoices.component';
import { BillInvoicesComponent } from './components/bill-invoices/bill-invoices.component';
import { CreateOnBoardingFormComponent } from './components/create-on-boarding-form/create-on-boarding-form.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { JobAssignmentRequestComponent } from './components/job-assignment-request/job-assignment-request.component';
import { RequestJobsComponent } from './components/request-jobs/request-jobs.component';
import { EditOnBoardingFormComponent } from './components/edit-on-boarding-form/edit-on-boarding-form.component';
import { ViewOnBoardingFormComponent } from './components/view-on-boarding-form/view-on-boarding-form.component';
import { AddressBankDetailsComponent } from './address-bank-details/address-bank-details.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'set-password', component: ResetPasswordComponent },
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'clients',
        component: ClientsComponent
      },
      {
        path: 'client/create',
        component: ClientCreateComponent
      },
      {
        path: 'client/update/:id',
        component: ClientUpdateComponent
      },
      {
        path: 'agencies',
        component: AgenciesComponent
      },
      {
        path: 'candidates',
        component: CandidatesComponent,
        children : [
          { path: '', redirectTo: 'active-candidates', pathMatch: 'full' },
          {
            path: 'active-candidates',
            component: ActiveCandidatesComponent,
          },
          {
            path: 'inactive-candidates',
            component: InactiveCandidatesComponent,
          },
        ],
      },
      {
        path: 'jobs',
        component: JobsComponent,
        children :[
          {path: '', redirectTo :'active-jobs', pathMatch: 'full'},
          {
            path: 'active-jobs',
            component: ActiveJobsComponent,
          }, {
            path : 'inactive-jobs',
            component: InactiveJobsComponent,
          }, {
            path : 'request-jobs',
            component: RequestJobsComponent,
          }
        ],
        
      },
      {
        path: 'timesheets',
        component: TimesheetsComponent
      },
      {
        path: 'clients-create',
        component: ClientsComponent
      },
      {
        path: 'tasks',
        component: TasksComponent
      },
      {
        path: 'tasks-list',
        component: TasksListComponent
      },
      {
        path: 'client-contacts',
        component: ClientContactsComponent
      },
      {
        path: 'settings',
        component: SettingsComponent,
        children: [
          { path: '', redirectTo: 'general', pathMatch: 'full' },
          {
            path: 'general',
            component: GeneralTabComponent,
          },
          {
            path: 'form',
            component: TimesheetFormsComponent,
          },
          {
            path: 'reminder',
            component: RemindersComponent,
          },
          {
            path: 'change-password',
            component: ChangePasswordComponent,
          },
          {
            path: 'flow',
            component: AuthorizationFlowTabComponent,
          },
          {
            path: 'address-bank-details',
            component: AddressBankDetailsComponent,
          },
        ],
      },
      {
        path: 'assignments',
        component: JobAssignmentsComponent,
        children : [
          {path : '', redirectTo: 'active-assignments', pathMatch: 'full'},
          {
            path : 'active-assignments',
            component: ActiveAssignmentsComponent,
          },
          {
            path: 'inactive-assignments',
            component: InactiveAssignmentsComponent,
          },
          {
            path: 'assignment-requests',
            component: JobAssignmentRequestComponent,
          }
        ]
      },
      {
        path: 'reports',
        component: ReportsComponent,
        children: [
          { path: '', redirectTo: 'pay-bill', pathMatch: 'full' },
          {
            path: 'pay-bill',
            component: PayBillReportComponent,
          },
          {
            path: 'payroll',
            component: PayrollReportComponent,
          },
        ]
      },
      {
        path: 'timesheet-forms',
        component: TimesheetFormsComponent,
      },
      {
        path: 'create-form',
        component: CreateFormComponent,
      },
      {
        path: 'invoices',
        component: InvoicesComponent,
        children : [
          { path: '', redirectTo: 'pay-invoices', pathMatch: 'full' },
          {
            path: 'pay-invoices',
            component: PayInvoicesComponent,
          },
          {
            path: 'bill-invoices',
            component: BillInvoicesComponent,
          },
        ],
      },
      { path: 'on-boarding-form', 
        children: [
          { 
            path: 'create', 
            component: CreateOnBoardingFormComponent 
          },
          { 
            path: 'edit/:uuid', 
            component: EditOnBoardingFormComponent
          },
          { 
            path: 'view/:uuid', 
            component: ViewOnBoardingFormComponent
          },
        ]
      },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
