import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal } from 'src/app/app.model';

import { ClientService } from 'src/app/services/client.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataSharedService } from '../../services/data-shared.service';


interface Alert {
	type: string;
	message: string;
	alert_class?: string;
}

@Component({
	selector: 'app-edit-client',
	templateUrl: './edit-client.component.html',
	styleUrls: ['./edit-client.component.css']
})

@Injectable({
	providedIn: 'root',
})

export class EditClientComponent implements OnInit {

	alerts: Alert[];

	// @Input() client: any;
	@Input() public client: any;

	@Output() deactivateEditClientContactModal: EventEmitter<any> = new EventEmitter();

	error!: string;
	loading: boolean = false;

	formValid: boolean = false;
	clientForm: FormGroup | any;

	clientUuid: any;
	email_type: number = 0;
	frequency: number = 1;
	day_to_receive: number = 1;
	time_to_receive: string = '17:00';
	clientformValid: boolean = false;
	paymentTerms: any = {};
	invoiceEmail: string = '';

	client_contact: any = {
		uuid: '',
		first_name: '',
		last_name: '',
		email: '',
		send_invite: true,
		timesheet_notification_settings: {
			email_type: this.email_type,
			frequency: this.frequency,
			day: this.day_to_receive,
			time: this.time_to_receive,
		}
	};

	contact_edit_index!: number;
	client_contacts: any = [];

	constructor(
		private clientService: ClientService,
		public authService: AuthenticationService,
		public dataSharedService: DataSharedService,
		private modalService: NgbModal,
		private activeModalService: NgbActiveModal,
	) {
		this.resetAlert();
	}

	resetAlert() {
		this.alerts = [];
	}

	closeAlert(alert: Alert) {
		this.alerts.splice(this.alerts.indexOf(alert), 1);
	}


	ngOnInit(): void {
		if (this.client.uuid) {
			this.clientUuid = this.client.uuid;
		}
		this.clientForm = new FormGroup({
			name: new FormControl(this.client.name, Validators.required),
			identifier: new FormControl(this.client.identifier, []),
			address_1: new FormControl(this.client.address.address_1, []),
			address_2: new FormControl(this.client.address.address_2, []),
			post_zip_code: new FormControl(this.client.address.post_zip_code, []),
			country: new FormControl(this.client.address.country, []),
			payment_terms: new FormControl(this.client.payment_terms, []),
			client_company_number: new FormControl(this.client.client_company_number, []),
			currency_format: new FormControl(this.client.currency_format, []),
			client_reference: new FormControl(this.client.client_reference, []),
			invoice_email: new FormControl('', []),
		});
		this.paymentTerms = this.client.payment_terms;
		this.invoiceEmail = this.client.invoice_email;
		this.getClient(this.client);
	}

	// Modal custom update data
	updateData(data: any): void {
		if (data?.client) {
			this.client = data?.client;
			this.getClient(this.client);
		}

		this.loading = false;
	}

	getClient(client: any) {
		if (client.contacts.length > 0) {
			client.contacts.forEach((data: any) => {
				if (data.timesheet_notification_settings.email_type == null) {
					data.timesheet_notification_settings.email_type = this.email_type;
					data.timesheet_notification_settings.day = this.day_to_receive;
					data.timesheet_notification_settings.frequency = this.frequency;
					data.timesheet_notification_settings.time = this.time_to_receive;
				}
			});

			this.formValid = true;
			this.client_contacts = client.contacts;

			this.contact_edit_index = Number(0);
			this.client_contact = {
				uuid: client.contacts[0]?.uuid,
				first_name: client.contacts[0]?.first_name,
				last_name: client.contacts[0]?.last_name,
				name: `${client.contacts[0]?.first_name} ${client.contacts[0]?.last_name}`,
				email: client.contacts[0]?.email,
				send_invite: client.contacts[0]?.send_invite,
				timesheet_notification_settings: client.contacts[0]?.timesheet_notification_settings
			};
		} else {
			this.client_contacts = [];

			// this.contact_edit_index;
			this.client_contact;
			this.ResetClientContact();
		}
	}

	onClose() {
		const modalRef = this.modalService.open(AlertModalComponent, {
			size: 'md',
			backdrop: false,
			modalDialogClass: 'alert-modal modal-dialog-centered',
		});

		let data: AlertModal = {
			message: 'Are you sure you want to discard Client Updation?',
			yesAction: 'Discard',
			noAction: 'Cancel',
			yesActionColor: '#0566EA',
			noActionColor: 'transparent',
		}
		modalRef.componentInstance.data = data;
		modalRef.result.then((data) => {
			if (data?.action === 'yes') {
				this.activeModalService.close();
			}
		});
	}

	ValidateEmail(email: string) {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		let email_regexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		return email_regexp.test(email);
	}

	async ClientContactValidation(event: any) {
		this.error = '';
		let hasErr = false;

		if (this.client_contact.first_name.trim() == '') {
			hasErr = true;
		}

		if (!hasErr && this.client_contact.last_name.trim() == '') {
			hasErr = true;
		}

		if (this.client_contact.email.trim() == '') {
			hasErr = true;
		} else {
			if (!this.ValidateEmail(this.client_contact.email.trim())) {
				hasErr = true;
			}
		}

		if (this.client_contact.timesheet_notification_settings.email_type == 1) {
			if (this.client_contact.timesheet_notification_settings.time.trim() == '') {
				hasErr = true;
			}
		}

		this.clientformValid = (hasErr) ? false : true;
	}

	async AddClientContact(event: any) {
		await this.ClientContactValidation(event);

		if (this.clientformValid) {

			this.loading = true;
			if (typeof this.contact_edit_index !== "undefined") {
				this.client_contacts[this.contact_edit_index] = {
					uuid: this.client_contact?.uuid,
					first_name: this.client_contact?.first_name,
					last_name: this.client_contact?.last_name,
					name: `${this.client_contact?.first_name} ${this.client_contact?.last_name}`,
					email: this.client_contact?.email,
					send_invite: this.client_contact?.send_invite,
					timesheet_notification_settings: this.client_contact?.timesheet_notification_settings
				}

				this.alerts.push({
					type: 'success',
					alert_class: "alert-success",
					message: 'Contact updated successfully'
				});
				setTimeout(() => { this.alerts = [] }, 2000);
			} else {
				this.client_contacts.push({
					uuid: this.client_contacts.length + 1,
					first_name: this.client_contact?.first_name,
					last_name: this.client_contact?.last_name,
					name: `${this.client_contact?.first_name} ${this.client_contact?.last_name}`,
					email: this.client_contact?.email,
					send_invite: this.client_contact?.send_invite,
					timesheet_notification_settings: this.client_contact?.timesheet_notification_settings
				});
				this.alerts.push({
					type: 'success',
					alert_class: "alert-success",
					message: 'Contact created successfully'
				});
				setTimeout(() => { this.alerts = [] }, 2000);
			}

			this.loading = false;
			// this.ResetClientContact();
		} else {
			this.error = "Please fill out this field";
		}
	}

	async EditClientContact(contact: any, contact_index: any) {
		this.clientformValid = false;

		this.contact_edit_index = contact_index;
		this.client_contact = {
			uuid: contact.uuid,
			first_name: contact?.first_name,
			last_name: contact?.last_name,
			email: contact?.email,
			send_invite: contact?.send_invite,
			timesheet_notification_settings: contact?.timesheet_notification_settings
		};
	}

	async DeleteClientContact(contact_index: any) {
		const modalRef = this.modalService.open(AlertModalComponent, {
			size: 'md',
			backdrop: false,
			modalDialogClass: 'alert-modal modal-dialog-centered',
		});

		let data: AlertModal = {
			message: 'Are you sure you want to delete this contact?',
			yesAction: 'Discard',
			noAction: 'Cancel',
			yesActionColor: '#0566EA',
			noActionColor: 'transparent',
		}
		modalRef.componentInstance.data = data;
		modalRef.result.then((data) => {
			if (data?.action === 'yes') {
				this.client_contacts.splice(contact_index, 1);

				this.error = '';
				this.ResetClientContact();
			}
		});
	}

	async deactivateClientContact(client: any, contact: any, contact_index: any) {
		this.loading = true;
		this.deactivateEditClientContactModal.emit({ client, contact });
	}

	ResetClientContact() {
		this.contact_edit_index;
		this.client_contact = {
			uuid: '',
			first_name: '',
			last_name: '',
			email: '',
			send_invite: true,
			timesheet_notification_settings: {
				email_type: this.email_type,
				frequency: this.frequency,
				day: this.day_to_receive,
				time: this.time_to_receive,
			}
		}
	}

	async clientValidation(event: any) {
		this.error = '';
		let hasErr = false;
		let clientNameElement = document.getElementById('clientName');
		if (this.clientForm.controls.name.errors || this.clientForm.controls.name.value.trim() == '') {
			if (clientNameElement !== null) {
				hasErr = true;
			}
		}

		if (hasErr) {
			this.error = "Client name field is required";
			this.formValid = false;
		} else {
			this.formValid = true;
		}
	}

	onSubmit() {
		this.loading = true;
		const client_uuid = this.clientUuid;
		let client_address = {
			'address_1': this.clientForm.value.address_1,
			'address_2': this.clientForm.value.address_2,
			'post_zip_code': this.clientForm.value.post_zip_code,
			'country': this.clientForm.value.country,
		};
		const payload = { client: this.clientForm.value, client_contacts: this.client_contacts, address: client_address };
		this.clientService.updateClient(client_uuid, payload).subscribe(res => {
			this.activeModalService.close({ refresh: true });
			this.loading = false;
		}, error => {
			this.loading = false;
			this.error = error;
		});
	}
}